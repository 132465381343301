<template>
  <div class="operator">
    <el-breadcrumb class="breadCrumb"
                   separator="/">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>操作员管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bgWhite mt20">
      <div class="formBtn">
        <el-button class="btn submit fr add"
                   @click="addForm('form')"
                   size="small"
                   type="primary"><i></i>新 增
        </el-button>
      </div>
      <div>
        <el-table :data="formList"
                  style="width: 100%">
          <el-table-column type="index"
                           label="序号"
                           width="80">
          </el-table-column>
          <el-table-column prop="realName"
                           label="用户名">
          </el-table-column>
          <el-table-column prop="userPhone"
                           label="手机号"
                           width="180">
            <template slot-scope="scope">
              {{scope.row.userPhone | phoneFilter }}
            </template>
          </el-table-column>
          <el-table-column prop="userType"
                           :formatter="isUserType"
                           label="角色">
            <!--            <template slot-scope="scope">-->
            <!--              <span>{{}}</span>-->
            <!--            </template>-->
          </el-table-column>
          <el-table-column prop="userStatus"
                           label="状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.userStatus == 1 ? 'success' : 'danger'"
                      disable-transitions>{{scope.row.userStatus =='1'?'正常':'冻结'}}
              </el-tag>
            </template>
          </el-table-column>
          <!-- 1.3增加认证状态  -->
          <el-table-column prop="faceStatus"
                           label="认证状态">
            <template slot-scope="scope">
              {{scope.row.faceStatus==1?'待人脸':scope.row.faceStatus==2?'验证成功':'验证失败'}}
            </template>
          </el-table-column>
          <el-table-column fixed="right"
                           label="操作"
                           width="280">
            <template slot-scope="scope">
              <div class="redText">
                <el-button @click="handleDetail(scope.row.id,scope.row.userType)"
                           type="text"
                           size="small">详情
                </el-button>
                <el-button v-if="scope.row.userType == '1'&&getbusinessRole=='0'&&scope.row.faceStatus==2"
                           @click="handleEdit(scope.row)"
                           type="text"
                           size="small">变更
                </el-button>
                <el-button v-if="scope.row.userType == '1'&&getbusinessRole=='0'&&scope.row.faceStatus==2"
                           @click="handleUpdate(scope.row)"
                           type="text"
                           size="small">修改角色
                </el-button>
                <el-button v-if="scope.row.userType == '2'"
                           @click="handleFrozen(scope.row)"
                           type="text"
                           size="small">
                  冻结
                </el-button>
                <el-button v-if="scope.row.userType == '2'"
                           @click="handleReset(scope.row)"
                           type="text"
                           size="small">
                  重置密码
                </el-button>
                <el-button v-if="scope.row.userType == '2'||scope.row.faceStatus==1||scope.row.faceStatus==3"
                           @click="handleEdit(scope.row)"
                           type="text"
                           size="small">编辑
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="queryInfo.page"
                       :page-sizes="[10, 20, 30, 40]"
                       :page-size="queryInfo.limit"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total"
                       class="mt20">
        </el-pagination>
      </div>
    </div>
    <!--冻结-->
    <el-dialog :show-close="false"
               :visible.sync="FrozenVisible"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               width="410px">
      <div class="contentTip flex5">
        <img class="tipIcon"
             src="@/assets/img/auth/tishi1.png"
             alt="">
        <div>
          <h1>冻结提示</h1>
          <p v-if="frozenType=='1'">请确认是否需要冻结该用户？</p>
          <p v-else>当前用户为“冻结”状态，是否需要解除？</p>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer formBtn">
        <el-button class="btn normal"
                   @click="FrozenVisible=false">取 消</el-button>
        <el-button class="btn submit"
                   type="primary"
                   @click="FrozenVisibleSave()">继 续</el-button>
      </span>
    </el-dialog>
    <!--重置密码-->
    <el-dialog :show-close="false"
               :visible.sync="ResetVisible"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               width="410px">
      <div class="contentTip flex5">
        <img class="tipIcon"
             src="@/assets/img/auth/tishi1.png"
             alt="">
        <div>
          <h1>重置密码提示</h1>
          <p>该操作员密码将重置为“lxdt123456”，为
            保证账号安全请提醒用户尽快修改密码！</p>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer formBtn">
        <el-button class="btn normal"
                   @click="ResetVisible = false">取 消</el-button>
        <el-button class="btn submit"
                   type="primary"
                   @click="ResetVisibleSave">继 续</el-button>
      </span>
    </el-dialog>
    <!--变更角色-->
    <el-dialog title="修改角色"
               :visible.sync="UpdateVisible"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               width="410px">
      <div class="contentTip">
        <el-form :model="updateForm"
                 :rules="rules"
                 ref="updateForm">
          <el-form-item label="产品角色："
                        prop="userRole">
            <el-checkbox-group v-model="updateForm.userRole">
              <el-checkbox v-for="(item,index) in updateForm.allRole"
                           :key="index"
                           :disabled="item.id==updateRole?true:false"
                           :label="item.id">{{item.nameCn}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer"
            class="dialog-footer formBtn">
        <el-button class="btn normal"
                   @click="UpdateVisible = false">取 消</el-button>
        <el-button class="btn submit"
                   type="primary"
                   @click="UpdateVisibleSave(updateForm)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Operator",
  data() {
    return {
      titleStatus: "",
      getbusinessRole: sessionStorage.getItem("businessRole"), //0小微企业 1核心企业 2保理公司 3担保公司 4律所
      formList: [], //列表数据
      frozenId: "", //冻结id
      frozenType: "", //冻结状态
      reSetId: "", //重置密码id
      updateId: "", //修改角色id
      FrozenVisible: false, //冻结弹窗
      ResetVisible: false, //重置弹窗
      UpdateVisible: false, //变更弹窗
      total: 0, //分页-总数
      queryInfo: {
        //分页数据
        limit: 10, //限制10
        page: 1, //页码
      },
      updateForm: {
        //变更form
        userRole: [], //用户角色
      }, //变更角色数据
      updateRole: "", //修改角色-userType
      rules: {
        userRole: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个角色",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  filters: {
    /**过滤手机号码344显示方法* value 手机号**/
    phoneFilter(value) {
      // console.log(value,'手机号')
      let start = value.slice(0, 3);
      let main = value.slice(3, 7);
      let end = value.slice(7);
      return start + " " + main + " " + end;
    },
  },
  computed: {
    ...mapState([
      "companyStatus", //企业状态
      "companyRole", //公司角色
    ]),
  },
  methods: {
    //获取列表数据
    async getList() {
      console.log(this.queryInfo.pageNum);
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/operatorManage/queryOperator",
        this.$qs.stringify(this.queryInfo)
      );
      if (res.code == 200) {
        this.formList = res.data.records;
        this.total = res.data.total;
        this.titleStatus = res.titleStatus;
        // this.queryInfo.pageSize = res.data.size;
      } else {
        this.$message.error(res.msg);
      }
    },
    //角色-编辑
    isUserType(row) {
      switch (row.userType) {
        case "1":
          return "管理员";
        case "2":
          return "操作员";
        default:
          break;
      }
    },
    //新增
    addForm() {
      if (this.companyRole == "0") {
        if (this.companyStatus == "3") {
          this.$router.push("/aside/operator/update");
        } else {
          this.$message.error("企业未完成认证");
        }
      } else {
        this.$router.push("/aside/operator/update");
      }
    },
    //详情
    handleDetail(id, role) {
      if (this.companyRole == "0") {
        if (this.companyStatus == "3") {
          this.$router.push({
            path: "/aside/operator/detail",
            query: { id: id, role: role },
          });
        } else {
          this.$message.error("企业未完成认证");
        }
      } else {
        this.$router.push({
          path: "/aside/operator/detail",
          query: { id: id, role: role },
        });
      }
    },
    //冻结
    handleFrozen(row) {
      if (this.companyRole == "0") {
        if (this.companyStatus == "3") {
          this.FrozenVisible = true;
          this.frozenId = row.id;
          this.frozenType = row.userStatus;
        } else {
          this.$message.error("企业未完成认证");
        }
      } else {
        this.FrozenVisible = true;
        this.frozenId = row.id;
        this.frozenType = row.userStatus;
      }
    },
    async FrozenVisibleSave() {
      var type;
      if (this.frozenType == "1") {
        type = "2";
      } else {
        type = "1";
      }
      let obj = {
        operatorId: this.frozenId,
        operatorUserType: type,
      };
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/operatorManage/freezeOperator",
        this.$qs.stringify(obj)
      );
      if (res.code == 200) {
        this.FrozenVisible = false;
        this.$message.success(res.msg);
        await this.getList();
      } else {
        this.FrozenVisible = false;
        this.$message.error(res.msg);
      }
    },
    //重置密码
    handleReset(row) {
      if (this.companyRole == "0") {
        //小微
        if (this.companyStatus == "3") {
          this.ResetVisible = true;
          this.reSetId = row.id;
        } else {
          this.$message.error("企业未完成认证");
        }
      } else {
        this.ResetVisible = true;
        this.reSetId = row.id;
      }
    },
    async ResetVisibleSave() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/operatorManage/resetPassword",
        this.$qs.stringify({ operatorId: this.reSetId })
      );
      if (res.code == 200) {
        this.ResetVisible = false;
        this.$message.success(res.msg);
        await this.getList();
      } else {
        this.ResetVisible = false;
        this.$message.error(res.msg);
      }
    },
    //编辑
    handleEdit(row) {
      if (this.titleStatus) {
        this.$confirm(
          "已添加其余管理员，是否需放弃“待人脸”/“验证失败”的管理员？",
          "变更提示",
          {
            confirmButtonText: "重新添加",
            cancelButtonText: "继续等待",
            type: "warning",
          }
        ).then(() => {
          if (this.companyStatus == "3") {
            this.$router.push({
              path: "/aside/operator/update",
              query: { id: row.id, role: row.userType },
            });
          } else {
            this.$message.error("企业未完成认证");
          }
        });
      } else {
        if (this.companyRole == "0") {
          if (this.companyStatus == "3") {
            this.$router.push({
              path: "/aside/operator/update",
              query: { id: row.id, role: row.userType },
            });
          } else {
            this.$message.error("企业未完成认证");
          }
        } else {
          this.$router.push({
            path: "/aside/operator/update",
            query: { id: row.id, role: row.userType },
          });
        }
      }
    },
    //变更角色
    async handleUpdate(row) {
      if (this.companyRole == "0") {
        if (this.companyStatus == "3") {
          this.updateId = row.id;
          this.updateRole = row.userType;
          const { data: res } = await this.$auth.post(
            "/cpiaoju-auth/operatorManage/AllUserRoleTo",
            this.$qs.stringify({
              userId: this.updateId,
            })
          );
          if (res.code == 200) {
            this.updateForm = res.data;
            this.UpdateVisible = true;
          } else {
            this.UpdateVisible = false;
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error("企业未完成认证");
        }
      }
    },
    async UpdateVisibleSave() {
      this.$refs.updateForm.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$auth.post(
            "/cpiaoju-auth/operatorManage/modifyRole",
            this.$qs.stringify({
              userId: this.updateId,
              productRole: JSON.stringify(this.updateForm.userRole),
            })
          );
          if (res.code == 200) {
            this.UpdateVisible = false;
            this.$message.success(res.msg);
            await this.getList();
          } else {
            this.UpdateVisible = false;
            this.$message.error(res.msg);
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    //监听 页面条数 改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      // 重新获取列表数据
      this.getList();
    },
    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      // 重新获取列表数据
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.bgWhite {
  /*height: 604px;*/
  height: auto;
}

.redText {
  /deep/ .el-button--text {
    color: #c70009;
    font-size: 14px;
  }
}

.contentTip {
  .tipIcon {
    width: 46px;
    height: 46px;
    margin-right: 20px;
  }

  h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    word-break: break-all;
    color: #666666;
  }
}

.mt50 {
  margin-top: 50px;
}

/deep/ .el-table thead th {
  background-color: #fafafa !important;
}

.btn.add {
  margin-bottom: 20px;
}

.btn.add:hover {
  border: 1px solid #c70009;
}
</style>
